.titre {
    display: flex;
    align-items: center;
    justify-content: center; /* Centrer horizontalement */
    font-weight: bold;
    font-size: 40px;
    background: linear-gradient(to right, #D9D9D9, #D9D9D9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 13vh; /* Ajout de la hauteur pour centrer verticalement */
  }

  @media (max-width: 768px) {
    .titre {
      font-size: 20px;

      height: 15vh; /* Ajout de la hauteur pour centrer verticalement */
    }
  
  }