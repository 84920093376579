/* Navbar.css */
.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 150px;
  background-color: #310273;
  
}


.navbar-container {
  margin-left: 500px;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 20px; /* Adjust the padding as needed */
}
@media (max-width: 768px) {
  .navbar-nav {
    
    display:block !important;
    font-size: 50px;
  }

  .navbar-container {
    margin-left: 20px;
  }

}




@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.navbar-container img {
  max-height: 100%;
  margin-left: 150px;
  animation: rotate 5s linear infinite; 
}