#root {
  width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  
}

html{
  height: 0px;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background-color: #250A40;
}
.search_grid {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
gap: 250px;
}

