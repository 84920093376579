/* Searchbar.css */

.searchbar-input {
  margin-top: 20px;
  margin-left: 20px;
  padding: 10px;
  width: 300px;
  border: 1px solid #5503A6;
  border-radius: 5px;
  font-size: 16px;
  background-color: #5503A6;
  color: #D9D9D9;
  height: 40px; /* Ajoutez cette ligne pour définir la hauteur */
}

.searchbar-button {
  margin-left: 5px;
  padding: 8px 16px;
  background-color: #8A03A6;
  color: #D9D9D9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  height: 40px; /* Ajoutez cette ligne pour définir la hauteur */
  gap:5px;
}

.searchbar-button:hover {
  background-color: #A60378;

}

