/* Styles for the grid component */
.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}



.grid-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #D9D9D9; 
}


.grid-container {
  background-color: #000;
  display: grid;
  grid-template-columns: repeat(var(--width), 120px);
  grid-template-rows: repeat(var(--height), 120px);
  gap: 5px;
  padding: 2px;
}

.cell {
  background-color: #5503A6;
  border: 1px solid #5503A6;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}


.cell:hover,
.hovered {
  background-color: #8A03A6;
  transform: scale(1.09); 
}


.cell.dragging {
  background-color: #8A03A6;
  transform: scale(1.09); 
}

/* Styles for range input */
input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none; /* For Firefox */
  background: #250A40;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 300px;
  height: 5px;
  background: #8A03A6;
  border: none;
  border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #5503A6;
  margin-top: -4px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #8A03A6;
}

input[type=range]::-moz-range-track {
  height: 5px;
  background: #8A03A6;
  border: none;
  border-radius: 3px;
}

input[type=range]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #5503A6;
  border: none;
}

/* Hide the focus outline in Firefox */
input[type=range]:focus::-moz-range-track {
  background: #8A03A6;
}

/* Styles for Clear and Download buttons */
.grid-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;

}

.grid-buttons button {
  padding: 8px 16px;
  background-color: #8A03A6;
  color: #D9D9D9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  height: 40px; /* Ajoutez cette ligne pour définir la hauteur */
  gap:5px;
}

.grid-buttons button:hover {
  background-color: #A60378;

}


