.liste-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0px;
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid #ccc;
    width: 200px;
    height: 350px;
    overflow-y: auto;
    margin-top: 10px;
    margin-left: 25px;
    border: 5px solid transparent;
  border-image: linear-gradient(to right, #5503A6, #5503A6) 1  / 10px round;
  background-color: 310273;
  background-image: linear-gradient(to right, #5503A6, #5503A6);
  overflow-x: auto;
    overflow-y: auto;
  }
  
  .liste-item {
    padding: 5px;
    text-align: center;

  }

 
  
  .liste-container:hover {
    overflow-x: hidden; /* Hide horizontal scrollbar on hover */
  }
  
  .liste-item:hover {
    transform: scale(1.05);
  }
  
  
  /* Ajoutez ces styles à votre fichier Grid.css */
.hovered {
  background-color: #8A03A6; /* Changement de la couleur de fond au survol */
  transform: scale(1.05); 
}

  .liste-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .liste-container::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: #8A03A6;
    border-radius: 10px;
    
    background-image: linear-gradient(to bottom, #8A03A6, #8A03A6);
    scrollbar-width: thin; /* définit la largeur de la scrollbar */
    scrollbar-color: #5503A6 #5503A6; 
  }
  
  /* La poignée de la scrollbar */
  .liste-container::-webkit-scrollbar-thumb {
    background-color: #5503A6;
    border-radius: 10px;
    border: 3px solid #310273;
  }
  
  /* Le bord de la poignée */
  .liste-container::-webkit-scrollbar-thumb:hover {
    background-color: #490290;
  }
  